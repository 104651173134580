import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import { ProjectsContext } from '../../_context';

class PortfolioPage extends React.Component<any, any> {
  static contextType = ProjectsContext;
  context!: React.ContextType<typeof ProjectsContext>;

  componentDidMount() {
    const { t } = this.props;
    document.title = t("projects.title");
  }

  public render() {
    const { t, i18n } = this.props;
    const currentLng = i18n.language;

    return (
      <div className="container page-container portfolio-container">
        <div className="row">
          {this.context.projects.map((project, i) => {
            return (
              <div key={i} className="col-12 col-sm-6 col-lg-4">
                <Link to={`/${currentLng}/portfolio/${project.id}`}>
                  <div className="portfolio-item">
                    <img
                      className="media-object"
                      alt={`portfolio project ${i}`}
                      src={`${process.env.PUBLIC_URL}/assets/images/projects/${project.thumbURL}`}
                    />
                    <div className="overlay">
                      <div className="caption">
                        <h4>{t("projects." + project.name + ".name")}</h4>
                        <small>{t("projects." + project.type)}</small>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const Wrapped = withTranslation("common", { withRef: true })(PortfolioPage);

export default withRouter(Wrapped as any);
