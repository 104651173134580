import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { Lang } from '../../models';

class Navbar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = { scrolled: false, menuShown: false };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        this.setState({ ...this.state, menuShown: false });
    }

    handleLangChange(lng: Lang) {
        this.props.onLangChange(lng);
    }

    handleScroll(event: any) {
        let scrollTop = window.pageYOffset;

        let scrolled = scrollTop > 5 ? true : false;

        this.setState({ ...this.state, scrolled });
    }

    toggleMenu() {
        let menuShown = !this.state.menuShown;
        this.setState({ ...this.state, menuShown });
    }

    public render() {
        const { t, i18n } = this.props;
        const currentLng = i18n.language;

        return (
            <nav className={'navbar navbar-expand-lg fixed-top ' + (this.state.scrolled ? 'scrolled' : '')}>
                <div className='container'>
                    <NavHashLink className='navbar-brand logo-text' to={`/${currentLng}#home`}>
                        INNAKATSEVA
                    </NavHashLink>

                    <button className='navbar-toggler' type='button' onClick={() => this.toggleMenu()}>
                        <i className='fa fa-bars'></i>
                    </button>
                    <div className={'collapse navbar-collapse' + (this.state.menuShown ? ' show' : '')}>
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <NavHashLink
                                    smooth
                                    to={`/${currentLng}#home`}
                                    className='nav-link'
                                    activeClassName='active'
                                    exact>
                                    {t('menu.home')}
                                </NavHashLink>
                            </li>
                            <li className='nav-item'>
                                <NavHashLink
                                    smooth
                                    to={`/${currentLng}#about`}
                                    className='nav-link'
                                    activeClassName='active'>
                                    {t('menu.about')}
                                </NavHashLink>
                            </li>
                            <li className='nav-item'>
                                <NavHashLink
                                    smooth
                                    to={`/${currentLng}#portfolio`}
                                    className='nav-link'
                                    activeClassName='active'>
                                    {t('menu.portfolio')}
                                </NavHashLink>
                            </li>
                            <li className='nav-item'>
                                <NavHashLink
                                    smooth
                                    to={`/${currentLng}#contact`}
                                    className='nav-link'
                                    activeClassName='active'>
                                    {t('menu.contact')}
                                </NavHashLink>
                            </li>
                            <li className='nav-item language-btns'>
                                <button
                                    onClick={() => this.handleLangChange(Lang.HE)}
                                    className={
                                        'btn btn-sm ' +
                                        (currentLng === Lang.HE ? 'btn-secondary' : ' btn-outline-secondary')
                                    }>
                                    HE
                                </button>
                                <button
                                    onClick={() => this.handleLangChange(Lang.EN)}
                                    className={
                                        'btn btn-sm ' +
                                        (currentLng === Lang.EN ? 'btn-secondary' : ' btn-outline-secondary')
                                    }>
                                    EN
                                </button>
                                <button
                                    onClick={() => this.handleLangChange(Lang.RU)}
                                    className={
                                        'btn btn-sm ' +
                                        (currentLng === Lang.RU ? 'btn-secondary' : ' btn-outline-secondary')
                                    }>
                                    RU
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

const Wrapped = withTranslation('common', { withRef: true })(Navbar);

export default withRouter(Wrapped as any);
