import { Project } from './../models';

export const PROJECTS: Array<Project> = [
    {
        id: '1',
        thumbURL: 'p2tmb.jpg',
        name: 'mediterranean_sea',
        type: 'mural',
        images: ['p2_1.jpg', 'p2_2.jpg', 'p2_3.jpg', 'p2_4.jpg']
    },
    {
        id: '2',
        thumbURL: 'p3tmb.jpg',
        name: 'marina',
        type: 'mural',
        images: ['p3_1.jpg', 'p3_2.jpg']
    },
    {
        id: '3',
        thumbURL: 'p4tmb.jpg',
        name: 'prince',
        type: 'mural',
        images: ['p4_1.jpg', 'p4_2.jpg', 'p4_3.jpg']
    },
    {
        id: '4',
        thumbURL: 'p5tmb.jpg',
        name: 'jaffa',
        type: 'mural',
        images: ['p5_1.jpg', 'p5_2.jpg', 'p5_3.jpg']
    },
    {
        id: '5',
        thumbURL: 'p6tmb.jpg',
        name: 'tlv',
        type: 'mural',
        images: ['p6_1.jpg', 'p6_2.jpg', 'p6_3.jpg']
    },
    {
        id: '6',
        thumbURL: 'p1tmb.jpg',
        name: 'nurdia',
        type: 'mural',
        images: ['p1_1.jpg', 'p1_2.jpg', 'p1_3.jpg', 'p1_4.jpg']
    },
    {
        id: '7',
        thumbURL: 'p7tmb.jpg',
        name: 'carmiel',
        type: 'mural',
        images: ['p7_1.jpg', 'p7_2.jpg']
    },
    {
        id: '8',
        thumbURL: 'p8tmb.jpg',
        name: 'haifa',
        type: 'mural',
        images: ['p8_1.jpg', 'p8_2.jpg', 'p8_3.jpg']
    },
    {
        id: '9',
        thumbURL: 'p9tmb.jpg',
        name: 'jerusalem',
        type: 'mural',
        images: ['p9_1.jpg', 'p9_2.jpg', 'p9_3.jpg']
    }
];
