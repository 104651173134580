import React from 'react';

class Footer extends React.Component<any, any> {
  public render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col text-right">
              <a href="https://www.facebook.com/muralinna">
                <i className="fa fa-facebook fa-2x"></i>
              </a>
              <a href="https://www.instagram.com/innartinspace">
                <i className="fa fa-instagram fa-2x"></i>
              </a>
              <a href="https://www.pinterest.com/innaka71">
                <i className="fa fa-pinterest fa-2x"></i>
              </a>
              <a href="https://il.linkedin.com/in/inna-katseva-6ba94711">
                <i className="fa fa-linkedin fa-2x"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
