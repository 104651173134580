import React from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch, withRouter } from 'react-router-dom';

import { DEFAULT_LANG, Lang } from './models';

import Page404 from './pages/Page404';

import PortfolioProject from './pages/portfolio/PortfolioProject';
import SinglePageContainer from './pages/SinglePageContainer';
import Footer from './ui/components/Footer';
import Navbar from './ui/components/Navbar';

class App extends React.Component<any, any> {
    private currentLang = '';

    constructor(props: any) {
        super(props);
        this.state = { scrolled: false };
        this.handleScroll = this.handleScroll.bind(this);
        this.changeLang = this.changeLang.bind(this);
    }

    public componentDidMount(): void {
        const { match } = this.props;

        if (match.params.lang) {
            if (Object.values(Lang).includes(match.params.lang)) {
                this.changeLang(match.params.lang);
            } else {
                this.changeLang(DEFAULT_LANG);
            }
        } else {
            this.changeLang(DEFAULT_LANG);
        }

        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public changeLang(lng: string) {
        const { i18n, location } = this.props;
        i18n.changeLanguage(lng);
        this.currentLang = i18n.language;

        if (location.pathname) {
            const array = location.pathname.split('/');
            array[1] = this.currentLang;
            const newLocation = array.join('/');
            this.props.history.push(newLocation);
        } else {
            this.props.history.push('/en/');
        }
    }

    handleScroll(event: any) {
        let scrollTop = window.pageYOffset;

        let scrolled = scrollTop > 5 ? true : false;

        this.setState({ ...this.state, scrolled });
    }

    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        const navProps = this.changeLang;
        return (
            <div className={this.currentLang === Lang.HE ? 'rtl' : 'ltr'}>
                <Navbar {...{ onLangChange: navProps }}></Navbar>

                <Switch>
                    <Route exact path='/:lang/' component={SinglePageContainer} />
                    <Route exact path='/:lang/portfolio/:id' component={PortfolioProject} />
                    <Route exact path='*' component={Page404} />
                </Switch>

                <Footer></Footer>

                {this.state.scrolled && (
                    <button onClick={this.topFunction} className='btn btn-lg btn-secondary scroll-up-btn'>
                        <span className='fa fa-chevron-up'></span>
                    </button>
                )}
            </div>
        );
    }
}

const Wrapped = withTranslation('common', { withRef: true })(App);

export default withRouter(Wrapped as any);
