import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import About from './About';
import ContactPage from './contact/ContactPage';
import Home from './Home';
import PortfolioPage from './portfolio/PortfolioPage';

export interface SinglePageContainerProps {}

const SinglePageContainer = (props: SinglePageContainerProps) => {
    return (
        <>
            <section id='home'>
                <Home />
            </section>
            <section id='about'>
                <About />
            </section>
            <section id='portfolio'>
                <PortfolioPage />
            </section>
            <section id='contact'>
                <ContactPage />
            </section>
        </>
    );
};

const Wrapped = withTranslation('common', { withRef: true })(SinglePageContainer);

export default withRouter(Wrapped as any);
