import React from 'react';

import { SliderProps, SliderState } from './../../models';

export default class Slider extends React.Component<SliderProps, SliderState> {
  private interval: any;

  constructor(props: SliderProps) {
    super(props);
    this.state = { count: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let c = this.state.count + 1;
      if (c >= this.props.images.length) {
        c = 0;
      }

      this.setState({ count: c });
    }, 6000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="images-slider embed-responsive embed-responsive-21by9">
        {this.props.images.map((img, i) => {
          let divStyle = {
            backgroundImage: `url(${process.env.PUBLIC_URL}/${this.props.folder}/${img})`
          };
          return (
            <div
              className={
                "embed-responsive-item slide " +
                (i === this.state.count ? "current" : "")
              }
              key={i}
              style={divStyle}
            ></div>
          );
        })}
      </div>
    );
  }
}
