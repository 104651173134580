import './index.scss';

import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ProjectsContext } from './_context';
import { PROJECTS } from './_context/projects.data';
import App from './App';
import * as serviceWorker from './serviceWorker';
import common_en from './translations/en/common.json';
import common_he from './translations/he/common.json';
import common_ru from './translations/ru/common.json';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en', // language to use
    resources: {
        en: {
            common: common_en, // 'common' is our custom namespace
        },
        he: {
            common: common_he,
        },
        ru: {
            common: common_ru,
        },
    },
});

ReactDOM.render(
    <ProjectsContext.Provider value={{ projects: [...PROJECTS] }}>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </ProjectsContext.Provider>,

    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
