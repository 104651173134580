import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { FormContext, IFormContext } from '../../_context';
import { IErrors, IFormState, IValues } from '../../models';

class Form extends React.Component<any, IFormState> {
    constructor(props: any) {
        super(props);

        const errors: IErrors = {};
        const values: IValues = {};
        this.state = {
            errors,
            values,
        };
    }

    /**
     * Stores new field values in state
     * @param {IValues} values - The new field values
     */
    private setValues = (values: IValues) => {
        this.setState({ values: { ...this.state.values, ...values } });
    };

    /**
     * Returns whether there are any errors in the errors object that is passed in
     * @param {IErrors} errors - The field errors
     */
    private haveErrors(errors: IErrors) {
        let haveError: boolean = false;
        // eslint-disable-next-line
        Object.keys(errors).map((key: string) => {
            if (errors[key].length > 0) {
                haveError = true;
            }
        });
        return haveError;
    }

    /**
     * Handles form submission
     * @param {React.FormEvent<HTMLFormElement>} e - The form event
     */
    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();

        // console.log(this.state.values);

        if (this.validateForm()) {
            this.setState({ sending: true });
            const submitSuccess: boolean = await this.submitForm();
            this.setState({ sending: false, submitSuccess });
            if (submitSuccess) {
                this.setState({ values: {}, errors: {} });
            }
        }
    };

    /**
     * Executes the validation rules for all the fields on the form and sets the error state
     * @returns {boolean} - Whether the form is valid or not
     */
    private validateForm(): boolean {
        const errors: IErrors = {};
        // eslint-disable-next-line
        Object.keys(this.props.fields).map((fieldName: string) => {
            errors[fieldName] = this.validate(fieldName);
        });
        this.setState({ errors });
        return !this.haveErrors(errors);
    }

    /**
     * Executes the validation rule for the field and updates the form errors
     * @param {string} fieldName - The field to validate
     * @returns {string} - The error message
     */
    private validate = (fieldName: string): string => {
        let newError: string = '';
        const { t } = this.props;

        if (this.props.fields[fieldName] && this.props.fields[fieldName].validation) {
            newError = this.props.fields[fieldName].validation!.rule(
                this.state.values,
                fieldName,
                this.props.fields[fieldName].validation!.args
            );
        }

        newError = t(newError);

        this.setState({
            errors: { ...this.state.errors, [fieldName]: newError },
        });

        return newError;
    };

    /**
     * Submits the form to the http api
     * @returns {boolean} - Whether the form submission was successful or not
     */
    private async submitForm(): Promise<boolean> {
        try {
            const formData = new FormData();

            for (const name in this.state.values) {
                formData.append(name, this.state.values[name]);
            }

            const response = await fetch(this.props.action, {
                method: 'post',
                body: formData,
            });
            return response.ok;
        } catch (ex) {
            return false;
        }
    }

    public render() {
        const { t } = this.props;
        const { submitSuccess, errors, sending } = this.state;
        const context: IFormContext = {
            ...this.state,
            setValues: this.setValues,
            validate: this.validate,
        };
        return (
            <FormContext.Provider value={context}>
                <form onSubmit={this.handleSubmit} noValidate={true}>
                    {this.props.render()}
                    <div className='form-group'>
                        <button type='submit' className='btn btn-success' disabled={this.haveErrors(errors) || sending}>
                            {sending && <i className='fa fa-cog fa-spin fa-fw'></i>}
                            {t('contact.send')}
                        </button>
                    </div>
                    {submitSuccess && (
                        <div className='alert alert-success' role='alert'>
                            {t('contact.success')}
                        </div>
                    )}
                    {submitSuccess === false && !this.haveErrors(errors) && (
                        <div className='alert alert-danger' role='alert'>
                            {t('contact.error')}
                        </div>
                    )}
                </form>
            </FormContext.Provider>
        );
    }
}

export default withTranslation('common', { withRef: true })(Form);
