import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import * as aboutImage from '../assets/images/about.jpg';
import { Lang } from '../models';

class About extends React.Component<any, any> {
  componentDidMount() {
    const { t } = this.props;
    document.title = t("about.title");
  }

  public render() {
    const { i18n } = this.props;

    const currentLang = i18n.language;

    return (
      <div className="container page-container">
        <div className="row">
          <div className="col-12 col-md-5 pt-2">
            <img
              alt="Inna Katseva"
              className="img-fluid"
              src={String(aboutImage)}
            />
          </div>
          <div
            className={
              "text-justify col-12 col-md-7 " +
              (currentLang === Lang.HE ? "rtl" : "ltr")
            }
          >
            <Trans i18nKey="about.text" ns="common"></Trans>
          </div>
        </div>
      </div>
    );
  }
}

const Wrapped = withTranslation("common", { withRef: true })(About);

export default withRouter(Wrapped as any);
