import * as React from 'react';

import { FormContext } from '../../_context';
import { IErrors, IFieldProps } from '../../models';

export const Field: React.SFC<IFieldProps> = ({
  id,
  label,
  type,
  editor,
  value
}) => {
  const getError = (errors: IErrors): string => (errors ? errors[id] : "");
  const getErrorClass = (errors: IErrors): any =>
    getError(errors) ? "is-invalid" : "";

  return (
    <FormContext.Consumer>
      {(context: any) => (
        <div className="form-group">
          {editor!.toLowerCase() === "textbox" && (
            <input
              id={id}
              type={type}
              placeholder={label + ":"}
              value={value}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                context.setValues({ [id]: e.currentTarget.value })
              }
              onBlur={() => context.validate(id)}
              className={"form-control " + getErrorClass(context.errors)}
            />
          )}

          {editor!.toLowerCase() === "multilinetextbox" && (
            <textarea
              id={id}
              value={value}
              rows={3}
              placeholder={label + ":"}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                context.setValues({ [id]: e.currentTarget.value })
              }
              onBlur={() => context.validate(id)}
              className={"form-control " + getErrorClass(context.errors)}
            />
          )}

          {getError(context.errors) && (
            <div className="invalid-feedback">{getError(context.errors)}</div>
          )}
        </div>
      )}
    </FormContext.Consumer>
  );
};

Field.defaultProps = {
  editor: "textbox",
  type: "text"
};
