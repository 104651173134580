import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IFields, Lang } from '../../models';
import { Field } from './Field';
import Form from './Form';
import { isEmail, required } from './Validators';

class ContactPage extends React.Component<any, any> {
    componentDidMount() {
        const { t } = this.props;
        document.title = t('contact.title');
    }

    public render() {
        const { t, i18n } = this.props;
        const currentLang = i18n.language;

        const fields: IFields = {
            name: {
                id: 'name',
                label: t('contact.name'),
                type: 'text',
                validation: { rule: required },
            },
            email: {
                id: 'email',
                label: t('contact.email'),
                type: 'email',
                validation: { rule: isEmail },
            },
            tel: {
                id: 'tel',
                label: t('contact.tel'),
                type: 'tel',
            },
            notes: {
                id: 'notes',
                label: t('contact.yourMessage'),
                editor: 'multilinetextbox',
                validation: { rule: required },
            },
        };

        return (
            <div className='container page-container'>
                <div className='row'>
                    <div
                        className={
                            'col-12 col-md-5 col-lg-4 ' + (currentLang === Lang.HE ? 'text-right' : 'text-left')
                        }>
                        <h5>{t('contact.formTitle')}</h5>

                        <Form
                            action='http://innakatseva.com/mailer/feedback_script.php'
                            fields={fields}
                            render={() => (
                                <React.Fragment>
                                    <Field {...fields.name} />
                                    <Field {...fields.email} />
                                    <Field {...fields.tel} />
                                    <Field {...fields.notes} />
                                </React.Fragment>
                            )}
                        />
                    </div>
                    <div className='col-12 col-md-7 col-lg-8'>
                        <div className='embed-responsive embed-responsive-4by3'>
                            <iframe
                                title='Map'
                                className='embed-responsive-item'
                                scrolling='no'
                                src='https://maps.google.com/maps?q=Mevo+Yehoash+1%2C+Ashdod&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near'></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const Wrapped = withTranslation('common', { withRef: true })(ContactPage);
export default withRouter(Wrapped as any);

// {"success":true,"data":{"fields":[],"link":"","message":"Your details were sent successfully!"}}
/* Request URL: http://innakatseva.com/wp-admin/admin-ajax.php
Request Method: POST
Status Code: 200 OK
Remote Address: 192.115.31.6:80
Referrer Policy: no-referrer-when-downgrade
Access-Control-Allow-Credentials: true
Access-Control-Allow-Origin: http://innakatseva.com
Cache-Control: no-cache, must-revalidate, max-age=0
Connection: Upgrade, Keep-Alive
Content-Encoding: gzip
Content-Type: application/json; charset=UTF-8
Date: Tue, 03 Mar 2020 20:23:59 GMT
Expires: Wed, 11 Jan 1984 05:00:00 GMT
Keep-Alive: timeout=5, max=100
Referrer-Policy: strict-origin-when-cross-origin
Server: Apache
Transfer-Encoding: chunked
Upgrade: h2,h2c
Vary: Accept-Encoding
X-Content-Type-Options: nosniff
X-Frame-Options: SAMEORIGIN
X-Robots-Tag: noindex
X-UA-Compatible: IE=edge,chrome=1 */
//Accept: application/json, text/javascript, */*; q=0.01
/* Accept-Encoding: gzip, deflate
Accept-Language: en-US,en;q=0.9,ru;q=0.8,he;q=0.7
Connection: keep-alive
Content-Length: 208
Content-Type: application/x-www-form-urlencoded; charset=UTF-8
Cookie: wordpress_test_cookie=WP+Cookie+check; wp-settings-1=libraryContent%3Dbrowse%26editor%3Dtinymce%26hidetb%3D1%26advImgDetails%3Dshow; wp-settings-time-1=1581854315; pll_language=en; _sm_au_c=kOKURAC+RwAoXksx8drADutz6JRQemSHyCVUo+dFbNW0gAAAAnPFQ2EUgF5zHndYBJby82w5cuhqOL754/gx/UYsirps=
Host: innakatseva.com
Origin: http://innakatseva.com
Referer: http://innakatseva.com/?page_id=17
User-Agent: Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.122 Safari/537.36
X-Requested-With: XMLHttpRequest
action: pojo_form_contact_submit
form_id: 709
_nonce: 76fda8533e
_wp_http_referer: /?page_id=17
form_field_1: test
form_field_2: test@aaa.com
form_field_3: 54543543534
form_field_4: 34534 43543543 43543543 43 */
