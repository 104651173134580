import * as React from 'react';

import { IFormState, IValues } from '../models';

export interface IFormContext extends IFormState {
    /* Function that allows values in the values state to be set */
    setValues: (values: IValues) => void;
    /* Function that validates a field */
    validate: (fieldName: string) => void;
}

export const FormContext = React.createContext<IFormContext | {}>({});

