import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class Page404 extends React.Component<any, any> {
  public render() {
    // console.log(this.props);

    return (
      <div className="container mt-5">
        <p>404</p>
      </div>
    );
  }
}

const Wrapped = withTranslation("common", { withRef: true })(Page404);

export default withRouter(Wrapped as any);
