import * as React from 'react';

import { ProjectImages } from '../../models';

export default class PortfolioProjectImages extends React.Component<
  ProjectImages,
  any
> {
  imageClick(img: string) {
    console.log(img);
  }

  render() {
    return (
      <div className="project-images">
        {this.props.images.map((img, i) => {
          return (
            <div
              className="project-img"
              key={i}
              onClick={() => this.imageClick(img)}
            >
              <img
                className="img-fluid"
                alt={`project ${i}`}
                src={`${process.env.PUBLIC_URL}/assets/images/projects/${img}`}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
