import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import * as signutre2 from '../assets/images/signutre2.jpg';
import Slider from '../ui/components/Slider';

class Home extends React.Component<any, any> {
    private sliderImgs = ['hp_1.jpg', 'hp_3.jpg', 'hp_2.jpg', 'hp_4.jpg'];

    componentDidMount() {
        const { t } = this.props;
        document.title = t('home.title');
    }

    public render() {
        // console.log(this.props);

        const { t } = this.props;

        return (
            <div className='container page-container'>
                <div className='row'>
                    <div className='col'>
                        <Slider folder={'assets/images/hp'} images={[...this.sliderImgs]}></Slider>
                    </div>
                </div>
                <div className='row'>
                    <div className='col home-page-text text-justify'>
                        <hr />
                        <h2>{t('home.h2')}</h2>

                        <h4>
                            <Trans i18nKey='home.h4' ns='common'></Trans>
                        </h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <img alt='Inna Katseva' src={String(signutre2)} />
                        <hr />
                    </div>
                </div>
            </div>
        );
    }
}

const Wrapped = withTranslation('common', { withRef: true })(Home);

export default withRouter(Wrapped as any);
