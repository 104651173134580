import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Redirect, withRouter } from 'react-router-dom';

import { ProjectsContext } from '../../_context';
import { Project } from './../../models/project.model';
import PortfolioProjectImages from './PortfolioProjectImages';

export interface PortfolioProjectState {
  project: Project | undefined;
  nextProjectId?: string;
  prev?: string;
}
class PortfolioProject extends React.Component<any, PortfolioProjectState> {
  static contextType = ProjectsContext;
  context!: React.ContextType<typeof ProjectsContext>;

  componentDidMount() {
    const { match, t } = this.props;

    const project = this.context.projects.find(p => p.id === match.params.id);
    this.setState({ project });

    document.title = t("projects.title");
  }

  renderRedirect = () => {
    if (this.state && this.state.project === undefined) {
      const { i18n } = this.props;
      const currentLng = i18n.language;
      return <Redirect to={`/${currentLng}/notfound/`} />;
    } else if (this.state && this.state.project) {
      const { t } = this.props;
      return (
        <div className="project-container">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-11 col-lg-9 col-xl-8">
              <PortfolioProjectImages
                images={[...this.state.project.images]}
              ></PortfolioProjectImages>
            </div>
          </div>
          <div className="row">
            <div className="col project-capture text-center">
              <h4>{t("projects." + this.state.project.name + ".name")}</h4>
              <div className="project-desc">
                <Trans
                  i18nKey={
                    "projects." + this.state.project.name + ".description"
                  }
                  ns="common"
                ></Trans>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  public render() {
    return (
      <div className="container page-container">{this.renderRedirect()}</div>
    );
  }
}

const Wrapped = withTranslation("common", { withRef: true })(PortfolioProject);
export default withRouter(Wrapped as any);
